import * as React from 'react';
import Center from '../components/Center';
import Headline from '../components/Headline';
import styled from 'styled-components';
import Textbox from '../components/Textbox';

const ThisIsMe: React.FunctionComponent<{}> = props => {
    return (
        <Container id="me">
            <Headline>This is me.</Headline>
            <StyledTextbox textAlign={'center'}>
                I&apos;m a 22-year-old computer science major in his senior year at the Technical University of Munich.
                In my spare time, I&apos;m building{' '}
                <Link href="https://apps.shopify.com/discountcode-in-cart">apps for the Shopify app store</Link>,{' '}
                <Link href="https://clearcall.app">SaaS for e-commerce companies</Link>, or{' '}
                <Link href="https://infinitestory.app/">text-based adventure games</Link>.
            </StyledTextbox>
        </Container>
    );
};

const Container = styled(Center)`
    padding: 100px 100px;
    margin: 50px auto;

    @media (max-width: 600px) {
        padding: 100px 0;
        width: 80%;
        align-items: flex-start;
    }
`;

const StyledTextbox = styled(Textbox)`
    margin-top: 20px;
    max-width: 600px;

    @media (max-width: 600px) {
        text-align: left;
    }
`;

const Link = styled.a`
    color: inherit;
    &:visited {
        color: inherit;
    }
`;

export default ThisIsMe;
