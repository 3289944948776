import * as React from 'react';
import Headline from '../components/Headline';
import styled from 'styled-components';
import Textbox from '../components/Textbox';
import { colors } from '../theme';
import PropTypes from 'prop-types';

interface Props {
    children: React.ReactNode;
    headline: string;
    boxes: [React.ReactNode, React.ReactNode, React.ReactNode];
    squareSize: number;
    backgroundColor?: string;
}

const Researcher: React.FunctionComponent<Props> = ({ children, headline, boxes, squareSize, backgroundColor }) => {
    return (
        <Container>
            <ColoredContainer backgroundColor={backgroundColor} squareSize={squareSize}>
                <TextContainer>
                    <Headline>{headline}</Headline>
                    <StyledTextbox>{children}</StyledTextbox>
                </TextContainer>
                <SquareContainer squareSize={squareSize}>
                    <SquareContainerInner squareSize={squareSize}>
                        {boxes[0]}
                        {boxes[1]}
                        <Placeholder />
                        {boxes[2]}
                    </SquareContainerInner>
                </SquareContainer>
            </ColoredContainer>
        </Container>
    );
};

Researcher.propTypes = {
    children: PropTypes.node,
    headline: PropTypes.string.isRequired,
    boxes: PropTypes.any,
    squareSize: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string,
};

const Container = styled.div`
    width: 100%;
    max-width: 1400px;
    padding: 50px 50px;
    margin: 50px auto;

    @media (max-width: 600px) {
        padding: 0;
        margin: 0;
    }
`;

const SquareContainer = styled.div<{ squareSize: number }>`
    width: ${({ squareSize }) => squareSize}px;
    min-width: ${({ squareSize }) => squareSize}px;
    position: relative;
    z-index: 0;

    @media (max-width: 1050px) {
        width: 100%;
        max-width: 100%;
        height: ${({ squareSize }) => squareSize}px;
    }

    @media (max-width: 600px) {
        height: auto;
        margin-top: -20px;
        padding-bottom: 100px;
    }
`;

const SquareContainerInner = styled.div<{ squareSize: number }>`
    position: absolute;
    top: 50%;
    transform: translate(-${({ squareSize }) => squareSize}px, -65%);
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1050px) {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -10%);
        max-width: 100vw;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        position: relative;
        left: 0;
        transform: none;
        align-items: center;
        max-width: unset;
    }
`;

const ColoredContainer = styled.div<{ backgroundColor?: string; squareSize: number }>`
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: ${({ squareSize }) => squareSize * 2}px;
    border-radius: 5px;

    @media (max-width: 1050px) {
        flex-direction: column;
        min-height: 0;
        padding: 50px;
    }

    @media (max-width: 600px) {
        padding: 0;
    }
`;

const StyledTextbox = styled(Textbox)`
    margin-top: 20px;
    max-width: 600px;
`;

const TextContainer = styled.div`
    padding: 80px 50px;
    z-index: 999;
`;

const Placeholder = styled.div`
    @media (max-width: 1050px) {
        display: none;
    }
`;

export default Researcher;
