import * as React from 'react';
import Center from '../components/Center';
import Headline from '../components/Headline';
import styled from 'styled-components';
import Textbox from '../components/Textbox';
import { colors, shadow } from '../theme';

const ThisIsMe: React.FunctionComponent<{}> = props => {
    return (
        <Container>
            <ColoredBox>
                <Headline>Why Shopify?</Headline>
                <StyledTextbox>
                    I identify with Shopify and its mission. Shopify enables everybody to build their brand on an
                    infrastructure that was only accessible to billion-dollar companies before. I&apos;m inspired and
                    motivated by Tobi Lütke and the Snowdevil story. And I love living in a world where - thanks to
                    Shopify - creativity is more important than capital.
                </StyledTextbox>
            </ColoredBox>
            <ColoredBox>
                <Headline>Why me?</Headline>
                <StyledTextbox>
                    I&apos;m obsessed with personal growth and always searching for the next challenge. I&apos;m an
                    entrepreneur by heart and have a passion for coding and research. I know Shopify from the merchant
                    and developer perspective and am excited to shape the future of commerce with you.
                </StyledTextbox>
            </ColoredBox>
        </Container>
    );
};

const Container = styled(Center)`
    width: 100%;
    max-width: 1400px;
    padding: 50px 50px;
    margin: 50px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    align-items: stretch;

    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: 600px) {
        padding: 0;
    }
`;

const StyledTextbox = styled(Textbox)`
    margin-top: 20px;
    max-width: 600px;
`;

const ColoredBox = styled.div`
    background-color: ${colors.lightgreen};
    padding: 100px;
    border-radius: 5px;

    @media (max-width: 600px) {
        padding: 100px 50px;
    }
`;

export default ThisIsMe;
