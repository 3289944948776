import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSize } from '../theme';
import Button from './Button';
import Headline from './Headline';

interface Props {
    headline: string;
    portrait?: string;
    portraitMobile?: string;
    backgroundColor: string;
    buttonText?: string;
    children: React.ReactNode;
}

const Header: React.FunctionComponent<Props> = ({
    headline,
    portrait,
    portraitMobile,
    backgroundColor,
    buttonText,
    children,
}) => {
    return (
        <>
            <Container backgroundColor={backgroundColor} portrait={portrait}>
                <Headline primary>{headline}</Headline>
                <Textbox>{children}</Textbox>
                {buttonText && <Button label={buttonText} anchor="#me" />}
            </Container>
            <Img src={portraitMobile} />
        </>
    );
};

Header.propTypes = {
    headline: PropTypes.string.isRequired,
    portrait: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    children: PropTypes.node,
    portraitMobile: PropTypes.string,
};

const Container = styled.div<{ backgroundColor: string; portrait?: string }>`
    width: 100%;
    min-height: 550px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-image: url('${({ portrait }) => portrait}');
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 60px;
    overflow: hidden;
    position: relative;

    @media (max-width: 600px) {
        background-image: none;
        padding: 30px;
    }
`;

const Textbox = styled.div`
    max-width: 50%;
    font-size: ${fontSize.medium};
    margin: 20px 0 30px 0;

    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

const Img = styled.img`
    width: 100%;
    display: none;

    @media (max-width: 600px) {
        display: block;
    }
`;

export default Header;
