import * as React from 'react';
import Center from '../components/Center';
import styled from 'styled-components';
import Textbox from '../components/Textbox';
import { fontSize } from '../theme';

const SourceCode: React.FunctionComponent<{}> = props => {
    return (
        <Container>
            <StyledTextbox textAlign={'center'}>
                <Link href="https://github.com/flomllr/shopify_landingpage">
                    Check out the source code of this page.
                </Link>
            </StyledTextbox>
        </Container>
    );
};

const Container = styled(Center)`
    padding: 0 100px;
    margin: 50px auto;
    max-width: 100%;

    @media (max-width: 600px) {
        padding: 0;
        width: 80%;
        align-items: flex-start;
    }
`;

const StyledTextbox = styled(Textbox)`
    margin-top: 20px;
    max-width: 600px;
    font-size: ${fontSize.normal};
    @media (max-width: 600px) {
        text-align: left;
        max-width: 100%;
    }
`;

const Link = styled.a`
    color: inherit;
    &:visited {
        color: inherit;
    }
`;

export default SourceCode;
