import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontSize, shadow } from '../theme';

interface Props {
    size: number;
    children?: React.ReactNode;
    bold?: string;
    logo: string;
    link?: string;
}

const ProjectBox: React.FunctionComponent<Props> = ({ size, children, bold, logo, link }) => {
    return (
        <Square href={link} key={0} size={size}>
            <Logo>
                <LogoImg src={logo} />
            </Logo>
            <Description>
                {bold && <Brandname>{bold}</Brandname>} {children}
            </Description>
        </Square>
    );
};

ProjectBox.propTypes = {
    size: PropTypes.number.isRequired,
    children: PropTypes.node,
    bold: PropTypes.string,
    logo: PropTypes.string.isRequired,
    link: PropTypes.string,
};

const Square = styled.a<{ size: number }>`
    display: block;
    text-decoration: none;
    min-height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    max-width: 100vw;
    background-color: #fff;
    border-radius: 5px;
    margin: 5px;
    box-shadow: ${shadow};
    padding: 20px;
`;

const Logo = styled.div`
    display: flex;
    height: 80px;
    width: 40%;
    margin: 0 auto;
    align-items: center;
`;

const LogoImg = styled.img`
    width: 100%;
    border-radius: 6px;
`;

const Description = styled.div`
    color: ${colors.gray};
    font-size: ${fontSize.projectTile};
    margin: 20px 0;
`;

const Brandname = styled.span`
    color: ${colors.black};
    font-weight: 700;
    display: inline;
`;

export default ProjectBox;
