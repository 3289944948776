export const fontSize = {
    xLarge: '60px',
    large: '40px',
    medium: '19px',
    normal: '15px',
    button: '15px',
    projectTile: '18px',
};

export const colors = {
    black: '#212B35',
    blue: '#5C6AC4',
    darkblue: '#202e78',
    darkestblue: '#000639',
    white: '#fff',
    gray: 'rgba(34, 43, 53, 0.64)',
    pastellYellow: '#ffe9de',
    paleBlue: 'rgba(131, 158, 255, 0.28)',
    lightgreen: 'rgba(149, 191, 70, 0.45)',
};

export const shadow = '0px 4px 25px rgba(0, 0, 0, 0.1)';
