import * as React from 'react';
import Center from '../components/Center';
import Headline from '../components/Headline';
import styled from 'styled-components';
import Textbox from '../components/Textbox';
import { colors, shadow } from '../theme';

const Shopify: React.FunctionComponent<{}> = props => {
    return (
        <Container>
            <Column>
                <Headline color={colors.blue}>I&apos;m a Shopify —</Headline>
                <Headline>Merchant</Headline>
                <StyledTextbox>
                    In 2017, I discovered Shopify when looking for a way to sell self-designed apparel. My shop{' '}
                    <Link href="https://fancymerch.com">fancymerch.com</Link> attracted lots of attention after{' '}
                    <Link href="https://www.facebook.com/robertdowneyjr/posts/687906431377825">
                        Robert Downey Jr. posted about it on Facebook
                    </Link>
                    . After some time, I received a great offer to sell the store and accepted it to focus on new
                    projects.
                </StyledTextbox>
                <a href="https://www.facebook.com/robertdowneyjr/posts/687906431377825">
                    <Img src={require('../assets/post.jpg')} />
                </a>
            </Column>
            <Column>
                <Headline>Developer</Headline>
                <StyledTextbox>
                    As a merchant, I realized there was no easy way to have discount validation on the cart page, so I
                    built <Link href="https://apps.shopify.com/discountcode-in-cart">an app to add that feature</Link>.
                    The app is now used by over 1000 stores and generates enough income to cover my living expenses in
                    Munich.
                </StyledTextbox>
                <a href="https://apps.shopify.com/discountcode-in-cart">
                    <Img src={require('../assets/dcode.png')} />
                </a>
                <Headline>Enthusiast</Headline>
                <StyledTextbox>
                    My experience as a merchant and developer has made me a huge fan of Shopify and its ecosystem.
                    Shopify really makes commerce better for everybody involved - Customers, Merchants, Designers and
                    Developers. It took the complex thing online shops once were and turned it into a product for
                    everyone.
                </StyledTextbox>
            </Column>
        </Container>
    );
};

const Container = styled(Center)`
    width: 100%;
    max-width: 1000px;
    padding: 50px 50px;
    margin: 50px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    align-items: start;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

const Column = styled.div``;

const StyledTextbox = styled(Textbox)`
    margin-top: 20px;
    max-width: 600px;
`;

const Link = styled.a`
    color: inherit;
    &:visited {
        color: inherit;
    }
`;

const Img = styled.img`
    width: 100%;
    margin: 30px 0;
    box-shadow: ${shadow};
    border-radius: 5px;

    @media (max-width: 600px) {
        margin-bottom: 50px;
    }
`;

export default Shopify;
