import styled from 'styled-components';
import { colors, fontSize } from '../theme';

const Headline = styled.h1<{ primary?: boolean; color?: string }>`
    font-weight: 500;
    font-family: 'Roboto';
    font-size: ${({ primary }) => (primary ? fontSize.xLarge : fontSize.large)};
    color: ${({ color }) => color || colors.black};

    @media (max-width: 800px) {
        font-size: ${fontSize.large};
    }
`;

export default Headline;
