import * as React from 'react';
import { colors } from '../theme';
import TwoImageSection from '../components/TwoImageSection';
import lidar from '../assets/lidar.gif';
import rgb from '../assets/rgb.gif';
import lidarStatic from '../assets/lidar.png';
import rgbStatic from '../assets/rgb.png';

const Researcher: React.FunctionComponent<{}> = props => {
    return (
        <TwoImageSection
            headline="I'm a researcher."
            imageSize={300}
            backgroundColor={colors.pastellYellow}
            images={[rgb, lidar]}
            imagesStatic={[rgbStatic, lidarStatic]}
        >
            At my university, I’m working on a novel approach to train autonomous driving models using 3D point clouds.
            Using 3D data instead of regular images dramatically decreases the amount of data necessary to train the
            model, as new training examples can be generated from existing ones.
        </TwoImageSection>
    );
};

export default Researcher;
