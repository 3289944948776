import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontSize } from '../theme';

interface Props {
    label: string;
    onClick?: () => any;
    anchor?: string;
}

const Button: React.FunctionComponent<Props> = ({ label, onClick, anchor }) => {
    return (
        <Anchor href={anchor}>
            <Box onClick={onClick}>{label}</Box>
        </Anchor>
    );
};

Button.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    anchor: PropTypes.string,
};

const Box = styled.button`
    background-color: ${colors.blue};
    color: ${colors.white};
    padding: 13px 20px;
    outline: none;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    font-size: ${fontSize.button};
    font-weight: 600;
    transition: 150ms ease;
    border-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: ${colors.darkblue};
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    }

    &:active {
        background-color: ${colors.darkestblue};
        border-color: ${colors.darkestblue};
    }
`;

const Anchor = styled.a`
    cursor: pointer;
`;

export default Button;
