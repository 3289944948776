import React from 'react';
import Layout from './components/Layout';
import Header from './container/Header';
import ThisIsMe from './container/ThisIsMe';
import Researcher from './container/Researcher';
import Builder from './container/Builder';
import Shopify from './container/Shopify';
import WhyMe from './container/WhyMe';
import SourceCode from './container/SourceCode';

const App: React.FC = () => {
    return (
        <Layout>
            <Header />
            <ThisIsMe />
            <Researcher />
            <Builder />
            <Shopify />
            <WhyMe />
            <SourceCode />
        </Layout>
    );
};

export default App;
