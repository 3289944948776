import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
}

const Layout: React.FunctionComponent<Props> = ({ children }) => {
    return <Container>{children}</Container>;
};

Layout.propTypes = {
    children: PropTypes.node,
};

const Container = styled.div`
    width: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 1600px;

    @media (min-width: 1600px) {
        padding-top: 40px;
    }
`;
export default Layout;
