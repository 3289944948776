import * as React from 'react';
import Header from '../components/Header';
import portrait from '../assets/header.jpg';
import portraitMobile from '../assets/header_mobile.jpg';
const HeaderContainer: React.FunctionComponent<{}> = props => {
    return (
        <Header
            headline={'Dear Shopify.'}
            backgroundColor={'#c3cdd5'}
            buttonText={'Explore my story'}
            portrait={portrait}
            portraitMobile={portraitMobile}
        >
            My name is Florian Müller. Instead of submitting only one of my projects, I built this landing page to give
            you more context about who I am, what I&apos;m working on, and why I&apos;m the perfect fit for a Web
            Developer internship at Shopify.
        </Header>
    );
};

export default HeaderContainer;
