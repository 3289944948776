import * as React from 'react';
import Headline from './Headline';
import styled from 'styled-components';
import Textbox from './Textbox';
import { shadow } from '../theme';
import PropTypes from 'prop-types';

interface Props {
    images: [string, string];
    imagesStatic?: [string, string];
    headline: string;
    children: React.ReactNode;
    backgroundColor?: string;
    imageSize: number;
}

const Researcher: React.FunctionComponent<Props> = ({
    headline,
    children,
    backgroundColor,
    imageSize,
    images,
    imagesStatic,
}) => {
    return (
        <Container>
            <ColoredContainer backgroundColor={backgroundColor} imageSize={imageSize}>
                <ImageContainer imageSize={imageSize}>
                    <ImageContainerInner>
                        <Square imageSize={imageSize} backgroundImage={imagesStatic ? imagesStatic[0] : undefined}>
                            <Img src={images[0]} />
                        </Square>
                        <Square imageSize={imageSize} backgroundImage={imagesStatic ? imagesStatic[1] : undefined}>
                            <Img src={images[1]} />
                        </Square>
                    </ImageContainerInner>
                </ImageContainer>
                <TextContainer>
                    <Headline>{headline}</Headline>
                    <StyledTextbox>{children}</StyledTextbox>
                </TextContainer>
            </ColoredContainer>
        </Container>
    );
};

Researcher.propTypes = {
    headline: PropTypes.string.isRequired,
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
    imageSize: PropTypes.number.isRequired,
    images: PropTypes.any,
    imagesStatic: PropTypes.any,
};

const Container = styled.div`
    width: 100%;
    max-width: 1400px;
    padding: 50px 50px;
    margin: 50px auto;

    @media (max-width: 860px) {
        margin-top: 150px;
    }

    @media (max-width: 600px) {
        padding: 50px 0;
    }
`;

const Square = styled.div<{ imageSize: number; backgroundImage?: string }>`
    height: ${({ imageSize }) => imageSize}px;
    width: ${({ imageSize }) => imageSize}px;
    background-color: transparent;
    background-image: url("${({ backgroundImage }) => backgroundImage}");
    background-size: cover;
    background-position: center;
    margin: 10px;
    border-radius: 5px;
    box-shadow: ${shadow};
    overflow: hidden;
    position: relative;

    @media (max-width: 600px) {
        margin: 0;
        border-radius: 0;
    }
`;

const ImageContainer = styled.div<{ imageSize: number }>`
    width: ${({ imageSize }) => imageSize}px;
    min-width: ${({ imageSize }) => imageSize}px;
    position: relative;

    @media (max-width: 860px) {
        width: 100%;
        height: ${({ imageSize }) => imageSize / 2 - 50}px;
        min-height: ${({ imageSize }) => imageSize / 2 - 50}px;
    }
`;

const ImageContainerInner = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 860px) {
        display: flex;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
    }
`;

const ColoredContainer = styled.div<{ backgroundColor?: string; imageSize: number }>`
    background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: ${({ imageSize }) => imageSize * 1.6}px;
    border-radius: 5px;

    @media (max-width: 860px) {
        flex-direction: column;
    }

    @media (max-width: 600px) {
        padding: 0;
    }
`;

const StyledTextbox = styled(Textbox)`
    margin-top: 20px;
    max-width: 600px;
`;

const TextContainer = styled.div`
    padding: 100px 50px;
`;

const Img = styled.img`
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
`;

export default Researcher;
