import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import ThreeBoxSection from '../components/ThreeBoxSection';
import ProjectBox from '../components/ProjectBox';
const squareSize = 250;

const Researcher: React.FunctionComponent<{}> = props => {
    return (
        <ThreeBoxSection
            backgroundColor={colors.paleBlue}
            squareSize={squareSize}
            headline={"I'm a builder."}
            boxes={[
                <ProjectBox
                    bold={'Dcode'}
                    link={'https://apps.shopify.com/discountcode-in-cart'}
                    logo={require('../assets/dcode_logo.png')}
                    key={0}
                    size={squareSize}
                >
                    adds a discount validation field to the cart page of Shopify stores.
                </ProjectBox>,
                <ProjectBox
                    bold={'Clearcall'}
                    link={'https://clearcall.app'}
                    logo={require('../assets/clearcall_logo.svg')}
                    key={1}
                    size={squareSize}
                >
                    handles customer calls in non-business hours using a smart voice assistant.
                </ProjectBox>,
                <ProjectBox
                    bold={'The Infinite Story'}
                    link={'https://infinitestory.app'}
                    logo={require('../assets/infinite_story_logo.png')}
                    key={2}
                    size={squareSize}
                >
                    is a text-based adventure game, whose story is generated by an AI.
                </ProjectBox>,
            ]}
        >
            I have a passion for building apps and products. My Shopify app{' '}
            <Link href="https://apps.shopify.com/discountcode-in-cart">Dcode</Link> is used by over 1000 stores.{' '}
            <Link href="https://infinitestory.app">The Infinite Story</Link> attracted over 3000 users in the first
            week. Clearcall won the <Link href="https://pioneer.app/winners/florian-muller">Pioneer tournament</Link>{' '}
            and is used in production by a chinese e-commerce company with $1M in annual revenue, handling 1000 calls
            and saving the support staff 40 hours per month.
        </ThreeBoxSection>
    );
};

const Link = styled.a`
    color: inherit;
    &:visited {
        color: inherit;
    }
`;

export default Researcher;
